import * as React from 'react'
import * as Counters from 'react-counters'
import {
  Caption, ColumnSet, Table, TableCell, TableFooter, TableGroup, TableHeader, TableRow,
} from 'cnx-designer'
import { Editor, Node, Path, Point, Transforms } from 'slate'
import { RenderElementProps } from 'slate-react'
import { TABLE } from '../../counters'
import { pointMax, pointMin } from '../../utils'

// eslint-disable-next-line
export function onKeyDown(editor: Editor, ev: KeyboardEvent): void {
  switch (ev.key) {
  case 'Enter': return onEnter(editor, ev)
  case 'Backspace': return deleteContents(editor, ev, 'backward')
  case 'Delete': return deleteContents(editor, ev, 'forward')
  default:
  }
}
function onEnter(editor: Editor, ev: KeyboardEvent): void {
  const { selection } = editor

  if (selection == null) {
    return
  }

  const [tableRow, tableRowPath] = Editor.above(editor, { match: Table.isRow }) ?? []
  if (tableRow != null && tableRowPath != null) {
    const newPath = [...tableRowPath]
    newPath[newPath.length - 1] += 1
    if (Node.has(editor, newPath)) {
      newPath.push(selection.focus.path[newPath.length], 0)
      Transforms.select(editor, newPath)
    } else {
      const moveTillNotTab = () => {
        const [, tablePath] = Editor.above(editor, { match: Table.isTable }) ?? []
        if (tablePath) {
          Transforms.move(editor, { unit: 'line' })
          moveTillNotTab()
        }
      }
      const [, tablePath] = Editor.above(editor, { match: Table.isTable }) ?? []
      tablePath && Transforms.insertNodes(editor, { type: 'paragraph', children: [] }, { at: Path.next(tablePath) })
      moveTillNotTab()
    }

    // eslint-disable-next-line
    return ev.preventDefault()

  }
  const [tableCap] = Editor.above(editor, { match: Caption.isCaption }) ?? []
  if (tableCap != null) {
    // eslint-disable-next-line
    return ev.preventDefault()
  }
}

function deleteContents(
  editor: Editor,
  ev: KeyboardEvent,
  direction: 'forward' | 'backward',
): void {
  const { selection } = editor

  if (selection == null) return

  const [table] = Editor.above(editor, { match: Table.isTable }) ?? []
  if (table == null) return

  const start = pointMin(selection.anchor, selection.focus)
  const end = pointMax(selection.anchor, selection.focus)

  Transforms.collapse(editor, { edge: direction === 'forward' ? 'end' : 'start' })

  for (const [, path] of Editor.nodes(editor, { at: selection, match: Table.isTable })) {
    const anchor = pointMax(start, Editor.start(editor, path))
    const focus = pointMin(end, Editor.end(editor, path))

    if (!Point.equals(anchor, focus)) {
      Transforms.delete(editor, { at: { anchor, focus } })
    } else if (anchor.offset === 0 && direction === 'backward') {
      Transforms.move(editor, { unit: 'character', reverse: true })
    } else {
      return
    }
  }

  ev.preventDefault()
}

const ColumnsContext = React.createContext<ColumnSet>({
  columns: [],
  columnNames: {},
  spans: {},
})

export interface RenderTableProps extends RenderElementProps {
  element: Table
}

export function TableComp({ element, attributes, children }: RenderTableProps) {
  Counters.useCounter(attributes.ref, { increment: [TABLE] })

  return (
    <div
      className="adr-table"
      id={element.id as string | undefined}
      {...attributes}
    >
      {children}
    </div>
  )
}

export interface RenderTableGroupProps extends RenderElementProps {
  element: TableGroup
}

export function TableGroupComp({ element, attributes, children }: RenderTableGroupProps) {
  // FIXME: we need to recreate Table.columns here, as we don't have access to
  // element's path

  const set = React.useMemo(() => {
    const set: ColumnSet = {
      columns: element.columns,
      columnNames: {},
      spans: {},
    }

    for (const span of element.spans) {
      set.spans[span.name] = span
    }

    for (let i = 0; i < set.columns.length; ++i) {
      const column = set.columns[i]
      if (column.name != null) {
        set.columnNames[column.name] = i
      }
    }

    return set
  }, [element.columns, element.spans])

  return (
    <table className="adr-group" {...attributes}>
      <tbody>
        <ColumnsContext.Provider value={set}>
          {children}
        </ColumnsContext.Provider>
      </tbody>
    </table>
  )
}

function useHeaderFooterColumnSet(element: TableHeader | TableFooter): ColumnSet {
  const group = React.useContext(ColumnsContext)

  return React.useMemo(() => {
    if (element.columns == null) return group

    const set: ColumnSet = {
      columns: element.columns,
      columnNames: {},
      spans: {},
    }

    for (let i = 0; i < set.columns.length; ++i) {
      const column = set.columns[i]
      if (column.name != null) {
        set.columnNames[column.name] = i
      }
    }

    return set
  }, [group, element.columns])
}

export interface RenderTableHeaderProps extends RenderElementProps {
  element: TableHeader
}

export function TableHeaderComp({ element, attributes, children }: RenderTableHeaderProps) {
  const set = useHeaderFooterColumnSet(element)

  // TODO: mark as header
  return (
    <ColumnsContext.Provider value={set}>
      {children}
    </ColumnsContext.Provider>
  )
}

export interface RenderTableFooterProps extends RenderElementProps {
  element: TableFooter
}

export function TableFooterComp({ element, attributes, children }: RenderTableFooterProps) {
  const set = useHeaderFooterColumnSet(element)

  // TODO: mark as footer
  return (
    <ColumnsContext.Provider value={set}>
      {children}
    </ColumnsContext.Provider>
  )
}

export interface RenderTableRowProps extends RenderElementProps {
  element: TableRow
}

export function TableRowComp({ element, attributes, children }: RenderTableRowProps) {
  return (
    <tr {...attributes}>
      {children}
    </tr>
  )
}

export interface RenderTableCellProps extends RenderElementProps {
  element: TableCell
}

export function TableCellComp({ element, attributes, children }: RenderTableCellProps) {
  const columns = React.useContext(ColumnsContext)
  let colspan

  if (element.column == null || 'column' in element.column) {
    // Either implicit positioning or column-based positioning. In both cases
    // columns before this will position this correctly
  } else if ('start' in element.column) {
    const start = columns.columnNames[element.column.start]
    const end = columns.columnNames[element.column.end]
    colspan = end - start + 1
  } else {
    const start = columns.columnNames[columns.spans[element.column.span].start]
    const end = columns.columnNames[columns.spans[element.column.span].end]
    colspan = end - start + 1
  }

  return (
    <td rowSpan={element.rows} colSpan={colspan} {...attributes}>
      {children}
    </td>
  )
}
