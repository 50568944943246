/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://1d6b98abb755b837251990dd8adf2f95@sentry.naukosfera.com/5'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = 'b01f35fbb3d2c1c4cb53d181e77751fc3094f714'
